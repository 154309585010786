.schedule-timeslot-container {
  display: flex;
  flex-flow: wrap;
}

.add-schedule {
  width: 1.625rem;
  height: 1.625rem;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 2px 0;
}

.add-schedule:hover {
  background: #f5f5f5;
  text-decoration: none;
}

.schedule-timeslot {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  padding: 0 5px;
  align-items: center;
  margin: 2px 0.6rem 2px 0;
}

.schedule-timeslot .active {
  background: #f5f5f5;
}

.schedule-timeslot .error {
  border: 1px solid red;
}

.schedule-timeslot input {
  margin: 0;
  border: 0;
  padding: 0;
  width: 1.8rem;
  text-align: center;
  font-size: 14px;
  color: #595959;
  background: transparent;
}
.schedule-timeslot input:focus {
  outline: 0;
}

.schedule-timeslot input[type="number"]::-webkit-outer-spin-button,
.schedule-timeslot input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.schedule-timeslot input[type="number"] {
  -moz-appearance: textfield;
}
.schedule-timeslot small {
  height: 1.5rem;
  align-items: center;
  display: flex;
  width: 0.3rem;
  justify-content: flex-start;
}
.schedule-timeslot p {
  margin: 0 0 0 2px;
}

.schedule-timeslot-input {
  display: flex;
}

.timeslot-remove-action {
  display: flex;
  margin-left: 0.3rem;
  color: #8c8c8c;
  font-size: 14px;
  cursor: pointer;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#slide {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

html,
body {
  font-family: "nunito", sans-serif;
  font-weight: 400;
  font-style: normal;
}
